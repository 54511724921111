export default [
    {
        text: 'Webinars',
        value: 'webinars',
        icon: 'mdi-video' ,
        path: 'webinars',
        module : 'westudy',
        permission:'MODULE_WESTUDY',
        id :'webinars',

        owner : true
    },
    {
        text: 'StudentWebinar',
        value: 'webinars_student',
        icon: 'mdi-video' ,
        path: 'webinars_student',
        module : 'westudy',
        permission:'MODULE_WESTUDY',
        id :'webinars_student',

        student : true
    }
]